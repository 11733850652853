import React from "react";
import { Row, Col } from "react-bootstrap";
import Select, { StylesConfig } from "react-select";

const ClassTableFilters = () => {
  const colourStyles: StylesConfig<ColourOption, true> = {
    control: (styles) => ({ ...styles, backgroundColor: "white" }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        ":active": {
          backgroundColor: "#ffe5e5",
        },
        ":focus": {
          backgroundColor: "#ffe5e5",
        },
      };
    },
    multiValue: (styles) => {
      return {
        ...styles,
        padding: "3px",
        backgroundColor: "#ffe5e5",
      };
    },
    multiValueRemove: (styles, { data }) => ({
      ...styles,
      color: data.color,
      cursor: "pointer",
      ":hover": {
        backgroundColor: "#ff5764 ",
        color: "white",
      },
    }),
  };
  return (
    <Row className="mb-3">
      <Col lg={4}>
        <Col lg={5}>
          <Select
            name="date_range"
            // className="w-50"
            // value={dateRange}
            //   onChange={handleSelectChange}
            //   options={options}
            // getOptionLabel={(option) => `${option.label}`}
            // getOptionValue={(option) => `${option.label}`}
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            placeholder="Date range"
            styles={colourStyles}
            theme={(theme) => ({
              ...theme,
              colors: {
                ...theme.colors,
                primary25: "#ffe5e5",
                primary: "#ff8a93",
              },
            })}
          />
        </Col>
      </Col>
      <Col lg={8}>
        <Row className="align-items-end">
          <Col lg={3}>
            {/* <Row className="align-items-bottom justify-content-end">
              <Col> */}
            <Row>
              <a href="javascript:void(0)" className="ml-auto">
                Clear filters
              </a>
            </Row>
          </Col>
          {/* </Row>
          </Col> */}
          <Col lg={3}>
            <Select
              // onChange={(e) => handleSearch(e.name, "physio")}
              placeholder="Physio"
              styles={colourStyles}
              // options={filters.physio}
              // getOptionLabel={(option) => `${option.name}`}
              // getOptionValue={(option) => option.id}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#ffe5e5",
                  primary: "#ff8a93",
                },
              })}
            />
          </Col>
          <Col lg={3}>
            <Select
              // onChange={(e) => handleSearch(e.value, "studio")}
              placeholder="Location"
              // options={optionsArrayString(filters.location)}
              styles={colourStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#ffe5e5",
                  primary: "#ff8a93",
                },
              })}
            />
          </Col>
          <Col lg={3}>
            <Select
              // onChange={(e) => handleSearch(e.value, "status")}
              placeholder="Status"
              // options={optionsArrayString(filters.status)}
              styles={colourStyles}
              theme={(theme) => ({
                ...theme,
                colors: {
                  ...theme.colors,
                  primary25: "#ffe5e5",
                  primary: "#ff8a93",
                },
              })}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ClassTableFilters;
