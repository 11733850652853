import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import Filter from "../../../Other/Filter";
import TemplateListTable from "../components/tables/TemplateListTable";
import Link from "@material-ui/core/Link";
import { Link as RouterLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getTemplates } from "../_redux/templateActions";

const TemplatesPage = () => {
  const dispatch = useDispatch();

  const { list, listLoading } = useSelector((state) => state.classTemplates);

  useEffect(() => {
    dispatch(getTemplates());
  }, []);

  return (
    <Grid container>
      <Grid item lg={12}>
        <Filter />
      </Grid>
      <Grid item lg={12}>
        <Link component={RouterLink} to={`/class-plans/templates/new`}>
          <button className="btn btn-primary text-white rounded-pill ">
            <span className="px-5">+ Add new</span>
          </button>
        </Link>
      </Grid>
      <Grid item lg={12}>
        <TemplateListTable list={list} />
      </Grid>
    </Grid>
  );
};

export default TemplatesPage;
