import React, { useState } from "react";
import { Grid } from "@material-ui/core";
import ClassTableFilters from "../components/ClassTableFilters";
import ClassTableActionButtons from "../components/ClassTableActionButtons";
// import ClassTable from "../components/ClassTable";
import ClassTable from "../components/table/ClassTable";
const ClassesPage = () => {
  const [bulkEdit, setBulkEdit] = useState(false);

  const [selected, setSelected] = useState([]);

  const handleBulkEdit = () => {
    setBulkEdit(!bulkEdit);
  };

  return (
    <Grid container>
      <Grid item lg={12}>
        <ClassTableFilters />
      </Grid>
      <Grid item lg={12}>
        <ClassTableActionButtons
          handleBulkEdit={handleBulkEdit}
          bulkEdit={bulkEdit}
          selected={selected}
          setSelected={setSelected}
        />
      </Grid>
      <Grid item lg={12}>
        <ClassTable
          bulkEdit={bulkEdit}
          selected={selected}
          setSelected={setSelected}
        />
        {/* <ClassTable /> */}
      </Grid>
    </Grid>
  );
};

export default ClassesPage;
