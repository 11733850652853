import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import BulkEditClassModal from "./modal/BulkEditClassModal";

const ClassTableActionButtons = ({ handleBulkEdit, bulkEdit, selected }) => {
  const [bulkModal, setBulkModal] = useState(false);

  return (
    <Row className="mb-3 align-items-center">
      <BulkEditClassModal
        show={bulkModal}
        onHide={() => setBulkModal(false)}
        selected={selected}
      />
      <Col lg={6}>
        <Row className="align-items-center">
          <Col lg={2}>
            <Row className="align-items-center justify-content-center">
              <Col lg={3}>
                <a href="javascript:void(0)" onClick={handleBulkEdit}>
                  <span className="svg-icon svg-icon-sm svg-icon-primary ml-3 svg-icon-sort">
                    <SVG src={toAbsoluteUrl("/media/svg/icons/edit.svg")} />
                  </span>
                </a>
              </Col>
              <Col lg={8}>
                <span>Bulk edit</span>
              </Col>
            </Row>
          </Col>
          <Col lg={4}>
            <Col>
              {bulkEdit && (
                <button
                  className="btn btn-primary text-white rounded-pill "
                  disabled={selected.length == 0}
                  onClick={() => setBulkModal(true)}
                >
                  <span className="px-5">Add template to classes</span>
                </button>
              )}
            </Col>
          </Col>
        </Row>
      </Col>
      <Col lg={6}>
        <Col>
          <Row className="align-items-center justify-content-end">
            <button className="btn btn-primary text-white rounded-pill">
              Class history
            </button>
          </Row>
        </Col>
      </Col>
    </Row>
  );
};

export default ClassTableActionButtons;
