import { Card } from "@material-ui/core";
import React from "react";
import { Modal, Row, Col } from "react-bootstrap";
import Select from "react-select";
import CustomSelect from "../../../../Other/CustomSelect";

const BulkEditClassModal = (props) => {
  return (
    <Modal {...props} size="lg" centered>
      <Modal.Header>
        <h3 className="m-auto">Bulk edit classes</h3>
        <a href="javascript:void(0)" onClick={() => props.onHide()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </a>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center mb-5">
          <Col lg={12} className="text-center">
            <span>
              The below classes will be edited. Select a template from the drop
              down to add preset exercises to all classes below. Saving the
              changes will be publish the exercises to these classes.
            </span>
            {/* Are you sure you'd like to delete this exercises? This will remove
            the exercise from displaying on future exercise plan lists. */}
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={12}>
            <Card className="border p-5">
              <Row className="mb-3">
                <Col lg={5}>
                  <strong>Date</strong>
                </Col>
                <Col>
                  <strong>Type</strong>
                </Col>
                <Col>
                  <strong>Physio</strong>
                </Col>
                <Col>
                  <strong>Location</strong>
                </Col>
              </Row>
              {props.selected.map(() => (
                <Row className="mb-2">
                  <Col lg={5}>Monday 1st Jan, 9.15 - 10.15 am</Col>
                  <Col>Ref - Level 2</Col>
                  <Col>Rachel</Col>
                  <Col>Beaconsfield</Col>
                </Row>
              ))}
            </Card>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={3}>
            <CustomSelect placeholder="Select template" />
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col lg={3}>
            <button
              className="btn btn-secondary text-primary rounded-pill w-100"
              onClick={() => props.onHide()}
            >
              Cancel
            </button>
          </Col>
          <Col lg={3}>
            <button
              className="btn btn-primary text-white rounded-pill w-100"
              //   onClick={() => props.handleDeleteExercise()}
            >
              Save classes
            </button>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
};

export default BulkEditClassModal;
