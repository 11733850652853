import React, { useState, useEffect } from "react";
import { Row, Col, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import SelectExerciseRow from "./step2/SelectExerciseRow";

const Step2 = (props) => {
  const { actionsLoading, isSuccess } = useSelector(
    (state) => state.classTemplates
  );
  const [selectedExercises, setSelectedExercises] = useState([]);
  // const [newExercises, setNewExercises] = useState([]);

  const handleAddExercise = (exercise) => {
    // exercise.selected_variation = [];
    setSelectedExercises([...selectedExercises, exercise]);
  };

  const handleRemoveExercise = (exercise_id) => {
    // console.log("remove this exercise: ".exercise_id);
    let newExercises = [];
    selectedExercises.map((exercise) => {
      if (exercise.id !== exercise_id) {
        newExercises = [...newExercises, exercise];
      }
    });

    setSelectedExercises(newExercises);
  };

  const handleExerciseFieldChange = (target, val, exercise_id) => {
    let newExercises = [];
    selectedExercises.map((exercise) => {
      if (exercise.id === exercise_id) {
        exercise[target] = val;
      }
      newExercises = [...newExercises, exercise];
    });
    setSelectedExercises(newExercises);
  };

  // useEffect(() => {
  //   console.log(selectedExercises);
  // }, [selectedExercises]);

  return (
    <div>
      <Row className="p-2">
        <Col
          lg={12}
          style={{
            maxHeight: "500px",
            overflow: "auto",
          }}
        >
          {/* {props.searchedExercises.map((exercise, index) => (
            <SelectExerciseRow
              exercise={exercise}
              handleAddExercise={handleAddExercise}
              handleRemoveExercise={handleRemoveExercise}
              handleExerciseFieldChange={handleExerciseFieldChange}
            />
          ))} */}
          {props.searchedExercises.map((exercise) => {
            if (exercise.archived_at == null) {
              return (
                <SelectExerciseRow
                  exercise={exercise}
                  handleAddExercise={handleAddExercise}
                  handleRemoveExercise={handleRemoveExercise}
                  handleExerciseFieldChange={handleExerciseFieldChange}
                />
              );
            }
          })}
        </Col>
      </Row>
      <Row
        className="justify-content-center position-absolute w-100 pb-5"
        style={{ bottom: 0 }}
      >
        <Col lg={3}>
          <button
            className="btn btn-secondary text-primary rounded-pill w-100"
            onClick={() => props.onHide()}
            disabled={actionsLoading}
          >
            Cancel
          </button>
        </Col>
        <Col lg={3}>
          <button
            className="btn btn-primary text-white rounded-pill w-100"
            onClick={() => props.handleSave(selectedExercises)}
            disabled={actionsLoading}
          >
            {actionsLoading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                style={{
                  position: "absolute",
                  left: "2rem",
                  top: "1rem",
                }}
                aria-hidden="true"
              />
            )}
            Save
          </button>
        </Col>
      </Row>
    </div>
  );
};

export default Step2;
