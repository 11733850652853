import React, { useEffect, useState } from "react";
import { Card, Grid } from "@material-ui/core";
import {
  FormControl,
  Row,
  Col,
  ButtonToolbar,
  ToggleButton,
  ToggleButtonGroup,
  Button,
  Spinner,
} from "react-bootstrap";
import TemplateExercises from "./cards/TemplateExercises";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router-dom";
import Link from "@material-ui/core/Link";
import { StylesConfig } from "react-select";
import CreatableSelect from "react-select/creatable";
import CustomSelect from "../../../Other/CustomSelect";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  addExercisesToTemplate,
  fetchTags,
  mountDefaultTemplate,
  removeExercise,
  reOrderExercises,
  addVariation,
  removeVariation,
  handleTemplateFieldChange,
  handleSaveDraft,
  handleVariationIntChange,
  getTemplate,
} from "../_redux/templateActions";
import ExerciseContainer from "../../../Other/DragDropExercises/ExercisesContainer";
import TemplateSnackBar from "./snackbar/TemplateSnackBar";

const colourStyles: StylesConfig<ColourOption, true> = {
  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
  control: (styles) => ({ ...styles, backgroundColor: "white" }),
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      ":active": {
        backgroundColor: "#ffe5e5",
      },
      ":focus": {
        backgroundColor: "#ffe5e5",
      },
    };
  },
  multiValue: (styles) => {
    return {
      ...styles,
      padding: "3px",
      backgroundColor: "#ffe5e5",
    };
  },
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: data.color,
    cursor: "pointer",
    ":hover": {
      backgroundColor: "#ff5764 ",
      color: "white",
    },
  }),
};

const AdModTemplate = () => {
  const history = useHistory();
  const { id, mode } = useParams();
  const dispatch = useDispatch();
  const {
    templateUI,
    template,
    tags,
    saveDraftLoading,
    isSuccess,
    isError,
    draftSaved,
  } = useSelector((state) => state.classTemplates);

  const [readOnly, setReadOnly] = useState(false);

  const title =
    typeof mode !== "undefined"
      ? "View template"
      : typeof id !== "undefined"
      ? "Edit template"
      : "New template";

  useEffect(() => {
    if (mode == "read-only") {
      setReadOnly(true);
    }
  }, [mode]);

  const enableEdit = () => {
    setReadOnly(false);
  };

  useEffect(() => {
    dispatch(fetchTags());

    if (id) {
      dispatch(getTemplate(id));
    } else {
      dispatch(mountDefaultTemplate());
    }
  }, []);

  useEffect(() => {
    draftSaved &&
      setTimeout(() => {
        history.push(`/class-plans/templates/${template.id}/read-only`);
      }, 500);
  }, [draftSaved]);

  const handleAddExercise = (selectedExercises) => {
    dispatch(addExercisesToTemplate(selectedExercises));
  };

  const handleReorder = (newOrder) => {
    dispatch(reOrderExercises(newOrder));
  };

  const handleRemoveExercise = (index) => {
    dispatch(removeExercise(index));
  };

  const handleAddVariation = (index, variation) => {
    dispatch(addVariation(index, variation));
  };

  const handleRemoveVariation = (exercise_index, index) => {
    dispatch(removeVariation(exercise_index, index));
  };

  const handleIntChange = (exercise_index, index, target, val) => {
    dispatch(handleVariationIntChange(exercise_index, index, target, val));
  };

  const templateFieldChange = (target, val) => {
    // console.log(target, val);
    const payload = {
      target: target,
      val: val,
    };
    dispatch(handleTemplateFieldChange(payload));
  };

  const saveDraft = () => {
    dispatch(handleSaveDraft(templateUI));
  };

  return (
    <>
      <TemplateSnackBar />
      <Grid container>
        <Grid item lg={12}>
          <Row className="p-5 align-items-center justify-content-between">
            <Col>
              <h4>{title}</h4>
            </Col>
            <Col className="d-flex justify-content-end">
              {readOnly && (
                <Link
                  component={RouterLink}
                  to={`/class-plans/templates/${id}`}
                  color="inherit"
                  className="ml-auto"
                >
                  <Button
                    onClick={enableEdit}
                    variant="contained"
                    className="bg-primary rounded-pill text-white"
                    size="large"
                  >
                    Edit template
                  </Button>
                </Link>
              )}
            </Col>
          </Row>
        </Grid>
        <Grid item lg={12}>
          <Card className="border">
            <Row className="p-5 align-items-center">
              <Col lg={6}>
                <Row className="mb-3 align-items-center">
                  <Col lg={3}>Template name</Col>
                  <Col lg={6}>
                    <FormControl
                      placeholder="Template name"
                      disabled={readOnly}
                      value={templateUI?.name}
                      onChange={(e) =>
                        templateFieldChange("name", e.target.value)
                      }
                    />
                  </Col>
                </Row>
                <Row className="mb-3 align-items-center">
                  <Col lg={3}>Class types</Col>
                  <Col lg={6}>
                    <CustomSelect
                      isDisabled={readOnly}
                      placeholder="Class type"
                      options={tags?.class_types}
                      getOptionLabel={(option) => `${option.name}`}
                      getOptionValue={(option) => option.id}
                      menuPortalTarget={document.body}
                      value={templateUI?.class_types}
                      isMulti
                      onChange={(e) => templateFieldChange("class_types", e)}
                    />
                  </Col>
                </Row>
                <Row className="mb-3 align-items-center">
                  <Col lg={3}>Tags</Col>
                  <Col lg={6}>
                    <CreatableSelect
                      isMulti
                      placeholder="Tags"
                      isDisabled={readOnly}
                      styles={colourStyles}
                      menuPortalTarget={document.body}
                      value={templateUI?.tags}
                      theme={(theme) => ({
                        ...theme,
                        colors: {
                          ...theme.colors,
                          primary25: "#ff8a93",
                          primary: "#ffe5e5",
                        },
                      })}
                      onChange={(e) => templateFieldChange("tags", e)}
                    />
                  </Col>
                </Row>
              </Col>
              <Col lg={6}>
                <Row>
                  <Col lg={12}>
                    <Row className="align-items-center mb-2">
                      <Col lg={1}>Theme</Col>
                      <Col lg={6}>
                        <CreatableSelect
                          isMulti
                          placeholder="Theme"
                          isDisabled={readOnly}
                          styles={colourStyles}
                          menuPortalTarget={document.body}
                          value={templateUI?.themes}
                          theme={(theme) => ({
                            ...theme,
                            colors: {
                              ...theme.colors,
                              primary25: "#ff8a93",
                              primary: "#ffe5e5",
                            },
                          })}
                          onChange={(e) => templateFieldChange("themes", e)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={1}></Col>
                      <Col lg={6}>
                        {typeof templateUI?.level !== "undefined" && (
                          <ButtonToolbar>
                            <ToggleButtonGroup
                              type="radio"
                              name="options"
                              defaultValue={templateUI?.level}
                              style={{ zIndex: 0 }}
                              onChange={(option) =>
                                templateFieldChange("level", option)
                              }
                              // value={selectedFilters.level}
                            >
                              <ToggleButton value="basic" disabled={readOnly}>
                                Bas
                              </ToggleButton>
                              <ToggleButton
                                value="intermediate"
                                disabled={readOnly}
                              >
                                Int
                              </ToggleButton>
                              <ToggleButton
                                value="advanced"
                                disabled={readOnly}
                              >
                                Adv
                              </ToggleButton>
                            </ToggleButtonGroup>
                          </ButtonToolbar>
                        )}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Card>
        </Grid>
        <Grid
          item
          lg={12}
          className="mt-3"
          style={{
            minHeight: "450px",
          }}
        >
          {/* drag and drop exercises */}
          <ExerciseContainer
            exercises={templateUI?.exercises}
            readOnly={readOnly}
            handleAddExercise={handleAddExercise}
            reOrderExercises={handleReorder}
            handleRemoveExercise={handleRemoveExercise}
            handleAddVariation={handleAddVariation}
            handleRemoveVariation={handleRemoveVariation}
            handleIntChange={handleIntChange}
          />
          {/* <TemplateExercises
            exercises={templateUI?.exercises}
            readOnly={readOnly}
          /> */}
        </Grid>
      </Grid>
      <Grid container>
        <Grid item lg={12} className="mt-3">
          <Row className="justify-content-end">
            <Col lg={4} className="d-flex justify-content-between">
              <button
                className="btn btn-primary text-white rounded-pill"
                disabled={readOnly}
              >
                <span className="px-4">Duplicate</span>
              </button>
              <button
                className="btn btn-primary text-white rounded-pill"
                disabled={readOnly}
                onClick={saveDraft}
              >
                {saveDraftLoading && (
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                )}
                <span className="px-4">Save draft</span>
              </button>
              <button
                className="btn btn-primary text-white rounded-pill"
                disabled={readOnly}
              >
                <span className="px-4">Publish template</span>
              </button>
            </Col>
          </Row>
        </Grid>
      </Grid>
    </>
  );
};

export default AdModTemplate;
