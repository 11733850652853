import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Image } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { toAbsoluteUrl } from "../../../../../_metronic/_helpers";

const AddVariationModal = (props) => {
  const dispatch = useDispatch();
  const [selectedVariation, setSelectedVariation] = useState(null);
  const [equipmentVariations, setEquipmentVariations] = useState([]);

  useEffect(() => {
    let newVariations = [];
    // if (props.equipment_id !== null) {
    //   props.variationList.map((variation) => {
    //     if (variation.equipment == props.equipment_id) {
    //       newVariations = [...newVariations, variation];
    //     }
    //   });
    // } else {
    props.variationList.map((variation) => {

      if (props.equipment_id == null) {
        newVariations = [...newVariations, variation];
      } else {
        if (variation.equipment == props.equipment_id) {
          newVariations = [...newVariations, variation];
        }
      }
    });
    // }

    setEquipmentVariations(newVariations);
  }, [props.equipment_id]);

  const handleSave = (variation) => {
    let newVar = { ...variation };

    if (newVar.props.length > 0) {
      let newProps = [];

      newVar.props.map((pr) => {
        let newProp = { ...pr };
        newProp.properties = pr?.propsIcon?.properties;
        newProps = [...newProps, newProp];
      });
      newVar.props = newProps;
    }

    // console.log(variation);

    props.handleAddVariation(props.exercise_index, newVar);

    props.onHide();
  };

  const FilteredVariations = ({ variation }) => {
    const findVariation = props.exerciseVariations.find(
      (v) => v.id == variation.id
    );
    // console.log(findVariation);
    if (!findVariation) {
      return (
        <Col lg={12}>
          <button
            className="btn btn-primary text-white w-100 mb-3"
            onClick={() => handleSave(variation)}
          >
            <Row>
              <Col lg={5} className="d-flex justify-content-end">
                <Image
                  src={toAbsoluteUrl(
                    variation.image_link
                      ? variation.image_link
                      : "/media/images/default-exercise.png"
                  )}
                  height={20}
                  width={20}
                />
              </Col>
              <Col lg={7} className="d-flex justify-content-start">
                {variation.name}
              </Col>
            </Row>
          </button>
        </Col>
      );
    }

    return false;
  };

  const checkIfAllSelected = () => {
    let variationLength = equipmentVariations.length;

    props.exerciseVariations.map((eVariation) => {
      const findVariation = equipmentVariations.find(
        (v) => v.id == eVariation.id
      );
      if (findVariation) {
        variationLength--;
      }
    });

    return variationLength;
  };

  return (
    <Modal {...props} size="md" centered>
      <Modal.Header>
        <h3 className="m-auto">Select variations to add</h3>
        <a href="javascript:void(0)" onClick={() => props.onHide()}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="black"
            class="bi bi-x-lg"
            viewBox="0 0 16 16"
          >
            <path d="M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z" />
          </svg>
        </a>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center mb-5">
          <Col lg={10}>
            <Row>
              {equipmentVariations?.length == 0 ? (
                <Col lg={12} className="text-center">
                  No variations data
                </Col>
              ) : (
                checkIfAllSelected() == 0 && (
                  <Col lg={12} className="text-center">
                    Variations are already added
                  </Col>
                )
              )}
              {equipmentVariations.map((variation) => (
                <>
                  {/* {filterVariations(variation)} */}
                  <FilteredVariations variation={variation} />
                  {/* <button className="btn btn-primary text-white w-100 mb-3">
                    {variation.name}
                  </button> */}
                </>
              ))}
            </Row>
          </Col>
          {/* <Col lg={6}>
            <CustomSelect
              placeholder="Select variation"
              options={props.variationList}
              getOptionLabel={(option) => `${option.name}`}
              getOptionValue={(option) => option.id}
              onChange={(option) => {
                setSelectedVariation(option);
              }}
            />
          </Col> */}
        </Row>
        {/* <Row className="justify-content-center">
          <Col lg={4}>
            <button className="btn btn-secondary text-primary rounded-pill w-100">
              Cancel
            </button>
          </Col>
          <Col lg={4}>
            <button
              className="btn btn-primary text-white rounded-pill w-100"
              onClick={handleAddVariation}
              disabled={selectedVariation !== null ? false : true}
            >
              Save
            </button>
          </Col>
        </Row> */}
      </Modal.Body>
    </Modal>
  );
};

export default AddVariationModal;
