import { Card, Grid } from "@material-ui/core";
import React, { useState, useEffect } from "react";
import { Col, Row, Button } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import Link from "@material-ui/core/Link";
import TemplateExercises from "../../Templates/components/cards/TemplateExercises";
import PatientNotesModal from "./modal/PatientNotesModal";
import CustomSelect from "../../../Other/CustomSelect";

const AdModClassPage = () => {
  const { id, mode } = useParams();
  const [readOnly, setReadOnly] = useState(false);
  const [notesModal, setNotesModal] = useState(false);

  const title =
    typeof mode !== "undefined"
      ? "View Class"
      : typeof id !== "undefined"
      ? "Edit Class"
      : "New template";

  useEffect(() => {
    if (mode == "read-only") {
      setReadOnly(true);
    }
  }, [mode]);

  const enableEdit = () => {
    setReadOnly(false);
  };
  return (
    <Grid>
      <PatientNotesModal
        show={notesModal}
        onHide={() => setNotesModal(false)}
      />
      <Grid item lg={12}>
        <Row className="p-5 align-items-center justify-content-between">
          <Col>
            <h4>{title}</h4>
          </Col>
          <Col className="d-flex justify-content-end">
            {readOnly && (
              <Link
                component={RouterLink}
                to={`/class-plans/courses/${id}`}
                color="inherit"
                className="ml-auto"
              >
                <Button
                  onClick={enableEdit}
                  variant="contained"
                  className="bg-primary rounded-pill text-white"
                  size="large"
                >
                  Edit template
                </Button>
              </Link>
            )}
          </Col>
        </Row>
      </Grid>
      <Grid item lg={8}>
        <Card className="border p-5" style={{ backgroundColor: "lightgray" }}>
          <Row>
            <Col lg={6}>
              <Row className="p-3">
                <Col lg={4}>
                  <strong>Class</strong>
                </Col>
                <Col>Monday 1st jan, 8:16</Col>
              </Row>
              <Row className="p-3">
                <Col lg={4}>
                  <strong>Type</strong>
                </Col>
                <Col>Ref - Level 2</Col>
              </Row>
            </Col>
            <Col lg={6}>
              <Row className="p-3">
                <Col lg={4}>
                  <strong>Physio</strong>
                </Col>
                <Col>Rachel</Col>
              </Row>
              <Row className="p-3">
                <Col lg={4}>
                  <strong>Location</strong>
                </Col>
                <Col>Beaconsfield</Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Grid>
      <Grid item lg={12} className="my-4">
        {/* template select and notes button */}
        <Row>
          <Col lg={2}>
            <CustomSelect placeholder="Select template" isDisabled={readOnly} />
          </Col>
          <Col className="d-flex justify-content-end">
            <button
              className="btn btn-primary text-white rounded-pill"
              disabled={readOnly}
              onClick={() => setNotesModal(true)}
            >
              <span className="px-3">Add patient notes</span>
            </button>
          </Col>
        </Row>
      </Grid>
      <Grid item lg={12}>
        <TemplateExercises readOnly={readOnly} />
      </Grid>
      <Grid item lg={12} className="mt-3">
        <Row className="justify-content-end">
          <Col lg={4} className="d-flex justify-content-between">
            <button
              className="btn btn-primary text-white rounded-pill"
              disabled={readOnly}
            >
              <span className="px-4">Duplicate</span>
            </button>
            <button
              className="btn btn-primary text-white rounded-pill"
              disabled={readOnly}
            >
              <span className="px-4">Save draft</span>
            </button>
            <button
              className="btn btn-primary text-white rounded-pill"
              disabled={readOnly}
            >
              <span className="px-4">Publish template</span>
            </button>
          </Col>
        </Row>
      </Grid>
    </Grid>
  );
};

export default AdModClassPage;
